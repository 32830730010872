import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'
import { BSpinner } from 'bootstrap-vue'
import { SpinnerPlugin } from 'bootstrap-vue'
Vue.use(SpinnerPlugin)

import axios from "./libs/axios"
Vue.prototype.$http = axios;

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
//import BaiduMap from 'vue-baidu-map'

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.component('BSpinner', BSpinner);
Vue.prototype.$BSpinner =BSpinner;

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false;
Vue.prototype.GLOBAL=App;
/*Vue.use(BaiduMap, {
    // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key *!/
    ak: 'NtQ5oHwjfHKDwaaMnyQaYgR81waIlqNy'
})*/

// 添加请求拦截器，在请求头中加token
axios.interceptors.request.use(
    config => {
      if (localStorage.getItem('token')) {
        config.headers['Authorization'] ="Bearer"+" " +localStorage.getItem('token');
      }
      // if (localStorage.getItem('uid')) {
      //   config.headers['uid'] = localStorage.getItem('uid');
      // }
      return config;
    },
    error => {
      return Promise.reject(error);
    });
axios.interceptors.response.use(
    response => {
      // 未登录，返回登录页
      if (response.data.error === "401") {
        router.push('/login');
      }
      return response
    },
    error => {
      if(error.response){
        if(error.response.status === 401){
          router.push('/login');
        }
      }
      return Promise.reject(error)
    }
)




new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
